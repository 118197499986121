import React, { useEffect, useMemo } from "react";
import ReactFlow, {
  Node,
  Edge,
  useReactFlow,
  NodeChange,
  EdgeChange,
  Handle,
  Position,
  EdgeProps,
  getBezierPath,
} from "reactflow";
import { Player } from "./types/player";
import { Portrait } from "./components/Portrait";

export enum Side {
  LEFT,
  RIGHT,
  MIDDLE,
}

type PlayerNodeArgs = { data: { player: Player; animate: boolean } };

export const Graph = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
}: {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
}) => {
  const nodeTypes = useMemo(() => ({ player: PlayerNode }), []);
  const edgeTypes = useMemo(() => ({ link: CustomEdge }), []);
  const reactflow = useReactFlow();
  reactflow.fitView();

  useEffect(() => {
    const fitView = () => reactflow.fitView();
    window.addEventListener("resize", fitView);
    const task = setInterval(() => reactflow.fitView(), 1000);
    return () => {
      clearInterval(task);
      window.removeEventListener("resize", fitView);
    };
  }, [window, reactflow]);

  return (
    <div className="w-full h-full p-1">
      <ReactFlow
        className="flex flex-col items-center"
        nodes={nodes}
        edges={edges}
        autoPanOnNodeDrag={false}
        autoPanOnConnect={false}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        panOnDrag={false}
        panOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        minZoom={0}
        maxZoom={Number.MAX_VALUE}
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
      />
    </div>
  );
};

const PlayerNode = ({ data: { player, animate } }: PlayerNodeArgs) => {
  return (
    <div
      className={
        "flex flex-col items-center min-h-1/5 text-center drop-shadow-xl"
      }
    >
      <Portrait
        player={player}
        innerClassName="border-amber-300 border-4"
        animate={animate}
      />
      <div
        className={
          "font-bold text-black text-xl z-1 absolute -bottom-2 drop-shadow-lg " +
          "rounded whitespace-nowrap px-1 bg-amber-300 border border-black"
        }
      >
        {player.name}
      </div>
      <Handle
        className="opacity-0"
        id={`${player.id}-left`}
        type={"target"}
        position={Position.Left}
      />
      <Handle
        className="opacity-0"
        id={`${player.id}-right`}
        type={"source"}
        position={Position.Right}
      />
      <Handle
        className="opacity-0"
        id={`${player.id}-top`}
        type={"source"}
        position={Position.Top}
      />
      <Handle
        className="opacity-0"
        id={`${player.id}-bottom`}
        type={"target"}
        position={Position.Bottom}
      />
    </div>
  );
};

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const xEqual = sourceX === targetX;
  const yEqual = sourceY === targetY;

  const [edgePath] = getBezierPath({
    // we need this little hack in order to display the gradient for a straight line
    sourceX: xEqual ? sourceX + 0.0001 : sourceX,
    sourceY: yEqual ? sourceY + 0.0001 : sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="stroke-amber-300 stroke-[0.5rem] fill-none"
        d={edgePath}
        markerEnd={markerEnd}
      />
    </>
  );
}
