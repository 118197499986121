import React from "react";
import { Player } from "../types/player";

export function Portrait({
  player,
  innerClassName,
  animate,
}: {
  player: Player;
  innerClassName?: string;
  animate: boolean;
}) {
  return (
    <img
      style={{
        filter: !player.imageURL
          ? `hue-rotate(${(Number(player.id) % 12) * 30}deg)`
          : "",
        animation: animate ? "newestPlayer 0.75s" : "",
      }}
      className={`rounded-full object-scale-down border border-2 ${innerClassName}`}
      src={player.imageURL ?? "/default.png"}
    />
  );
}
