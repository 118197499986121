import { config } from "../config";
import { Player } from "../types/player";

const cachePlayer = (p: Player | null): Player | null => {
  if (p !== null) {
    localStorage.setItem(`linkup-player-${p.id}`, JSON.stringify(p));
  }
  return p;
};

const uncachePlayer = (id: string): Player | null => {
  const raw = localStorage.getItem(`linkup-player-${id}`);
  if (raw === null && raw !== "undefined") {
    return null;
  }
  return JSON.parse(raw);
};

export const getPlayer = (id: string): Promise<Player | null> => {
  const cachePlayer = uncachePlayer(id);
  if (cachePlayer !== null) {
    return Promise.resolve(cachePlayer);
  } else {
    return fetch(`${config.baseUrl}/players/${id}`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          console.error(`${resp.status} response from search`);
        }
      })
      .then(cachePlayer)
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
};

export const findPlayerConnections = (
  playerId: string,
  potentialConnectionIds: string[]
): Promise<string[]> =>
  fetch(`${config.baseUrl}/select`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      newPlayerId: playerId,
      oldPlayerIds: potentialConnectionIds,
    }),
  })
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        console.error(`${resp.status} response from search`);
      }
    })
    .catch((err) => {
      console.error(err);
      return [];
    });

const cacheSearch = (searchTerm: string, p: Array<Player>) => {
  if (p !== null) {
    localStorage.setItem(
      `linkup-search-${searchTerm}`,
      JSON.stringify(p.map((p) => p?.id))
    );
  }
  return p;
};

const uncacheSearch = (searchTerm: string): Promise<Array<Player> | null> => {
  const raw = localStorage.getItem(`linkup-search-${searchTerm}`);
  if (raw === null && raw !== "undefined") {
    return Promise.resolve(null);
  }
  return Promise.all(JSON.parse(raw).map((id: string) => getPlayer(id)));
};

export const searchPlayers = async (searchTerm: string): Promise<Player[]> => {
  const cacheSearchResult = await uncacheSearch(searchTerm);
  if (cacheSearchResult !== null) {
    return Promise.resolve(cacheSearchResult);
  } else {
    return fetch(`${config.baseUrl}/players`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchTerm,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(`${response.status} response from search`);
        }
        return response.json() as Promise<Player[]>;
      })
      .then((players: Player[]) => {
        cacheSearch(searchTerm, players);
        players.forEach((p) => cachePlayer(p));
        return players;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }
};
