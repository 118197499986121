import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useState, SyntheticEvent } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Player } from "./types/player";
import { Portrait } from "./components/Portrait";
import { searchPlayers } from "./api/players";
import { useDebouncedCallback } from "use-debounce";

let reqsInFlight = 0;

export const SearchBar = ({
  selectPlayer,
  excludePlayers,
  disabled,
}: {
  selectPlayer: (event: SyntheticEvent, p: Player | null) => void;
  excludePlayers: string[];
  disabled: boolean;
}) => {
  const [options, setOptions] = useState<Player[]>([]);
  const [loading, setLoading] = useState(false);

  const search = useDebouncedCallback(
    (s: string) => {
      reqsInFlight++;
      return searchPlayers(s)
        .then((opts) => {
          setOptions(opts);
        })
        .finally(() => {
          reqsInFlight--;
          if (reqsInFlight === 0) {
            setLoading(false);
          }
        });
    },
    200,
    { trailing: true }
  );

  return (
    <Autocomplete
      className="w-4/6"
      options={options}
      getOptionLabel={(p) => p.name}
      getOptionKey={(p) => p.id}
      disabled={disabled}
      isOptionEqualToValue={(v1, v2) => v1.id === v2.id}
      noOptionsText="No players found"
      autoHighlight
      onInputChange={(_, s: string | null) => {
        if (s !== null && s.length > 3) {
          setLoading(true);
          search(s);
        }
      }}
      onChange={selectPlayer}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon />,
            endAdornment: loading && <CircularProgress size="1.5rem" />,
            className: `${
              disabled ? "bg-slate-100" : "bg-white"
            } border border-black`,
            disabled,
          }}
        />
      )}
      renderOption={(props, player) => {
        return (
          <li {...props}>
            <div className="flex items-center my-2 gap-6 py-4 h-20 w-full min-w-min">
              <Portrait
                player={player}
                innerClassName="h-20 w-15 border-slate-500"
                animate={false}
              />
              <div className="flex flex-col gap-1 truncate">
                <span className="font-semibold text-sm md:text-base">
                  {player.name}
                </span>
                <span className="font-light text-xs md:text-small">
                  {player.position.main ?? "Unknown"},{" "}
                  {player.club.name === "" ? "Retired" : player.club.name}
                </span>
              </div>
            </div>
          </li>
        );
      }}
      filterOptions={(opt) => opt.filter((o) => !excludePlayers.includes(o.id))}
    />
  );
};
