import { Dialog, Slide } from "@mui/material";
import React from "react";

export const HelpModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      className="flex flex-col items-center justify-center h-full rounded-lg text-lg w-full"
      scroll="body"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px" } }}
      TransitionComponent={Slide}
      sx={{
        "& .MuiDialog-container": {
          width: "100%",
        },
      }}
    >
      <div className="bg-white flex flex-col w-full items-center p-8 gap-4 text-md text-center">
        <h1 className="font-black text-xl bg-[#219100] text-amber-300 py-1 px-2 rounded drop-shadow">
          Linkup
        </h1>
        <p>
          Linkup is a game where you try to connect two players by a chain of{" "}
          <span className="font-bold">
            players that have played for the same club at the same time.
          </span>{" "}
          National Teams do <em>not</em> count.
        </p>
        <video className="shadow" height={400} width={400} autoPlay loop muted>
          <source src="tutorial.webm" type="video/webm" />
        </video>
        <p>
          Try to join the player on the left to the player on the right via a
          series of links between players that have played in the same squad.
          You have <span className="font-bold">10 guesses</span> to connect
          them.
        </p>
        <p>
          You can work from left-to-right or right-to-left or meet in the
          middle.
        </p>
      </div>
    </Dialog>
  );
};
